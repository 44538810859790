<template>
  <div class="list">
    <div class="wrapper">
      <div style="margin-bottom: 20px;">
        <a-breadcrumb>
          <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
          <!-- <a-breadcrumb-item v-if="$route.query.cmsTypeId == 1">新闻公告</a-breadcrumb-item> -->
          <!-- <a-breadcrumb-item v-if="$route.query.cmsTypeId == 2">培训活动</a-breadcrumb-item> -->
          <a-breadcrumb-item>新闻公告</a-breadcrumb-item>
          <template #separator>
            <icon-right />
          </template>
        </a-breadcrumb>
      </div>
      <div class="list-container" >
        <div class="list-left">
          <!-- <div class="list-left-title" v-if="$route.query.cmsTypeId == 1">新闻公告</div> -->
          <!-- <div class="list-left-title" v-if="$route.query.cmsTypeId == 2">培训活动</div> -->
          <div class="list-left-title">新闻公告</div>
          <div class="list-left-box">
            <!-- <div class="tabs-nav" :style="{ 'top': topHeight + 'px'}"></div>
            <div class="list-left-item line-text-1" @click="handleTrigger(index)" :class="active == index ? 'active': ''" v-for="(item, index) in listLeft" :key="index">{{item}}</div> -->
          </div>
        </div>
        <div class="list-right" v-if="flag">
          <div class="floor-b" v-for="(item, index) in listRight.slice(0,10)" :key="index" @click="onClickDtl(item.id)">
            <div class="floor-b-left line-text-1">{{item.title}}</div>
            <div class="floor-b-right">{{item.publishTime}}</div>
          </div>
          <div class="pagination">
            <a-pagination
            :default-current="1"
            :page-item-style="{ 'border': '1px solid rgba(0,0,0,0.1500)', 'border-radius': '2px' }"
            :active-page-item-style="{ 'background-color': 'transparent', 'border': '1px solid #096DD9','color': '#096DD9'}"
            :total="total"
            @change="change" />
          </div>
        </div>
        <div class="list-rights" v-else>
          <div v-html="content" ></div>
          <div class="file">
            <div class="file-item" v-for="(item, index) in list" :key="index">
              <icon-file style="margin-right: 10px; color: #474747; font-size: 18px;" />
              <div class="file-name line-text-1">{{item}}</div>
              <div class="file-btn" @click="download">
                <icon-download style="margin-right: 4px; font-size: 18px;" />
                下载文件
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Message } from '@arco-design/web-vue'
import { getArticleList } from "@/api/common.js"

export default {
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      list: [
        '看接单接口送到家看接单接口送到家看接单接口送到家看接单接口送到家看接单接口送到家看接单接口送到家看接单接口送到家看接单接口送到家',
        'kdjdksjk就可的角度看'
      ],
      flag: true,
      content: '我是文章',
      topHeight: 0,
      active: 0,
      listLeft: [],
      listRight: [],
      total: 2
    })
    watch(() => route.query.active, (newText, oldText) => {
      if (newText) {
        console.log(newText)
        state.active = newText
        state.topHeight = newText * 44
      }
    }, { immediate: true })
    const handleTrigger = (index) => {
      if (index == state.active) return false
      state.active = index
      state.topHeight = index * 44
      if (index % 2 == 0) {
        state.flag = true
      } else {
        state.flag = false
      }
      router.push({
        path: '/new/list',
        query: {
          active: state.active
        }
      })
    }
    const download = () => {
      Message.success('下载中')
    }
    const change = (val) => {
      getArticle(val)
    }
    // 新闻
    const getArticle = (current) => {
      getArticleList({ current, size: 10, status: 2, cmsTypeId: 1 }).then(res => {
        if (res.code === 20000) {
          state.listRight = res.result.records
          state.total = res.result.total
        }
      })
    }
    getArticle(1)

    const onClickDtl = (id) => {
      const newpage = router.resolve({ path: '/new/detail', query: { cmsTypeId: 1, id} })
      window.open(newpage.href, '_blank')
    }

    return {
      ...toRefs(state),
      handleTrigger,
      download,
      change,
      onClickDtl
    }
  }
}
</script>

<style lang="less" scoped>
.list {
  background: #F7F6FA;
  padding: 20px 0;
  .list-container {
    display: flex;
    align-items: flex-start;
    .list-left {
      margin-right: 20px;
      width: 250px;
      // background: linear-gradient(135deg, #207AFF 0%, #7528EF 100%);
      background: linear-gradient(135deg, #1571F9 0%, #3B88F9 100%);
      border-radius: 4px;
      .list-left-title::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 24px;
        background: #fff;
        border-radius: 2px;
        left: 10px;
        top: 8px;
      }
      .list-left-title {
        margin: 15px 0;
        color: #fff;
        position: relative;
        font-size: 22px;
        padding: 0 30px;
        height: 40px;
        display: flex;
        align-items: center;
      }
      .list-left-box {
        position: relative;
        min-height: 430px;
        z-index: 3333;
        padding-bottom: 40px;
        background: url('../../assets/images/xw_bg.png') no-repeat bottom;
        .tabs-nav {
          position: absolute;
          left: 0;
          top: 0;
          width: 210px;
          height: 34px;
          border-radius: 0 17px 17px 0;
          transition: all .3s;
          background: #fff;
          z-index: -1;
        }
        .list-left-item.active {
          color: #207AFF;
          font-weight: 700;
        }
        .list-left-item {
          cursor: pointer;
          width: 210px;
          margin-bottom: 10px;
          height: 34px;
          line-height: 34px;
          font-size: 14px;
          color: #fff;
          padding-left: 40px;
          transition: all .3s;
        }
      }
    }
    .list-right {
      background: #fff;
      flex: 1;
      border-radius: 4px;
      height: 500px;
      padding: 20px;
      position: relative;
      .floor-b::before {
        position: absolute;
        content: '';
        left: 0;
        top: 16px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #D8D8D8;
      }
      .floor-b {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 20px;
        font-size: 14px;
        color: #474747;
        height: 38px;
        border-bottom: 1px #E9E9E9 dashed;
        .floor-b-left:hover {
          color: #096DD9;
        }
        .floor-b-left {
          flex: 1;
          cursor: pointer;
        }
        .floor-b-right {
          font-size: 12px;
          color: #999999;
          margin-left: 20px;
        }
      }
      .pagination {
        position: absolute;
        right: 20px;
        bottom: 30px;
      }
    }
    .list-rights {
      background: #fff;
      flex: 1;
      border-radius: 4px;
      padding: 40px 20px;
      position: relative;
      .file {
        margin-top: 40px;
      }
      .file-item:hover {
        background-color: #f0f3fa;
      }
      .file-item {
        cursor: pointer;
        margin-top: 10px;
        display: flex;
        align-items: center;
        height: 50px;
        background: #FAFAFA;
        border-radius: 4px;
        border: 1px solid #E9E9E9;
        padding: 0 15px;
        color: #474747;
        font-size: 14px;
        .file-name {
          flex: 1;
        }
        .file-btn {
          margin-left: 40px;
          width: 100px;
          height: 26px;
          background: #207AFF;
          border-radius: 4px;
          font-size: 14px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
